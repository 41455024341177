import React, { Component } from "react"
import { graphql } from "gatsby"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Layout from "../layouts/layout"
import Modules from "../components/modules/modules"

class CaseTemplate extends Component {
  
  movieOrImage() {
    const caseData = this.props.data.wordpressWpCase
    // console.log(caseData)
    if (caseData.acf.landscape || caseData.acf.portrait) {
      let landscapePosterURL, portraitPosterURL;
      if (caseData.acf.landscape_case) {
        landscapePosterURL = caseData.acf.landscape_case.localFile.publicURL
        portraitPosterURL = caseData.acf.portrait_case.localFile.publicURL
      } else {
        landscapePosterURL = null
        portraitPosterURL = null
      }
      return (
        <div className="video-container w-screen mb-85 md:mb-100 xl:mb-150">
          <video className="w-full md:hidden" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={portraitPosterURL} >
            <source src={caseData.acf.portrait.localFile.publicURL} type="video/mp4" />
          </video>
          <video className="w-full hidden md:block" autoPlay="autoplay" muted loop playsInline webkit-playsinline="true" poster={landscapePosterURL} style={{bottom: '100px'}}>
            <source src={caseData.acf.landscape.localFile.publicURL} type="video/mp4" />
          </video>
        </div>
      )
    } else if (caseData.acf.landscape_case) {
      return (
        <div>
          <Img fluid={caseData.acf.portrait_case.localFile.childImageSharp.fluid} className="w-screen mb-85 md:mb-100 xl:mb-150 md:hidden" />
          <Img fluid={caseData.acf.landscape_case.localFile.childImageSharp.fluid} className="w-screen mb-85 md:mb-100 xl:mb-150 hidden md:block" />
        </div>
      )
    }
  }

  
  renderCategories(categories) {
    let array = [];
    for (let index = 0; index < categories.length; index++) {
      const category = categories[index];
      array.push(
        <span className="link-small text-teal" key={category.wordpress_id}>{category.name}/</span>
      );
    }
    // _.join(array, '/')
    // console.log(array);
    return array;
  }

  getKeywords() {
    let categories = this.props.data.wordpressWpCase.kategori;
    let array = [];
    for (let index = 0; index < categories.length; index++) {
      const category = categories[index];
      array.push(
       category.name
      );
    }
    return array;
  }



  render() {
    const currentPage = this.props.data.wordpressWpCase
    // console.log(this.props);
    let shareImage = null;
    if (currentPage.acf.landscape_case) {
        shareImage = currentPage.acf.landscape_case.localFile.publicURL
    }
    return (
      <Layout footerfixed={true} headertransparent={true}>
        <SEO
          title={currentPage.title + ' - ' + currentPage.acf.client}
          description={currentPage.content}
          image={shareImage}
          keywords={this.getKeywords()}
        />
        <div className="case-hero">
          {this.movieOrImage()}
        </div>
        <main className="container px-5 mt-70 md:mt-100 xl:mt-150 wide:mt-200">
          <h3 className="mb-50 mt-10 md:mt-50 lg:mt-6 lg:mb-6 wide:mb-10">{this.renderCategories(currentPage.kategori)}</h3>
          <div className="flex flex-wrap mb-75 md:mb-100 xl:mb-150">
            <div className="w-full lg:w-1/2">
              <h1 className="title-xl md:text-6xl xl:text-72" dangerouslySetInnerHTML={{ __html: currentPage.title }} />
              <h2 className="title-xl font-thin md:text-6xl xl:text-72 mt-2 lg:mt-5" dangerouslySetInnerHTML={{ __html: currentPage.acf.client }} />
            </div>
            <div className="w-full lg:w-1/2 mt-50 md:mt-100 lg:mt-2 lg:pl-150">
              <article className="text-xlarge text-white" dangerouslySetInnerHTML={{ __html: currentPage.content }} />
            </div>
          </div>
          <Modules data={currentPage.acf.modules_case} />
        </main>
      </Layout>
    )
  }
}

export default CaseTemplate

export const pageQuery = graphql`
  query($id: String!) {
    wordpressWpCase(id: { eq: $id }) {
      title
      content
      date(formatString: "MMMM DD, YYYY")
      kategori {
        name
        slug
        wordpress_id
      }
      acf {
        client
        landscape {
          id
          localFile {
            publicURL
          }
        }
        portrait {
          id
          localFile {
            publicURL
          }
        }
        landscape_case {
          localFile {
              publicURL
              childImageSharp {
                  fluid(maxHeight: 800, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
        }
        portrait_case {
          localFile {
              publicURL
              childImageSharp {
                  fluid(maxHeight: 800, quality: 80) {
                  ...GatsbyImageSharpFluid_withWebp
                  }
              }
          }
        }
        modules_case {
          ... on WordPressAcf_title_and_text {
            id
            title
            text
          }
          ... on WordPressAcf_full_image_or_movie {
            id
            media
            movie {
              localFile {
                publicURL
              }
            }
            image {
              localFile {
                publicURL
                childImageSharp {
                    fluid(maxWidth: 1700, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp
                    }
                }
              }
            }
          }
          ... on WordPressAcf_slider {
            id
            images {
              id
              caption
              localFile {
                publicURL
                childImageSharp {
                    fluid(maxHeight: 844, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp
                      presentationWidth
                    }
                }
              }
            }
          }
          ... on WordPressAcf_gallery {
              id
              layout
              images {
                  caption
                  localFile {
                      childImageSharp {
                          fluid(maxWidth: 1700, quality: 100) {
                              ...GatsbyImageSharpFluid_withWebp
                          }
                      }
                  }
              }
          }
          ... on WordPressAcf_youtube {
            id
            playlist
            youtube_id
          }
          ... on WordPressAcf_numbers {
            id
            numbers {
              number
              text
              unit
            }
          }
        }
      }
    }
    site {
      id
      siteMetadata {
        title
      }
    }
  }
`